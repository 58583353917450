import React from 'react';
import { EuiFieldText, EuiFormRow } from '@elastic/eui';
import { useField } from 'formik';

interface Props {
    placeholder?: string;
    name: string;
    label: string;
    type?: string;
}

const HailCXTextField = (props: Props) => {
    const [field, meta] = useField(props.name);

    return (
        <EuiFormRow label={props.label} fullWidth
            isInvalid={meta.touched && !!meta.error}
            error={meta.error}
        >
            <EuiFieldText {...field} type={ props.type ?? 'text'} fullWidth
                isInvalid={meta.touched && !!meta.error}
                style={{fontSize: 16}}
            />
        </EuiFormRow>
    )
}

export default HailCXTextField;