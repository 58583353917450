import React, { useRef } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXSingleButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"
import { EuiForm, EuiSpacer, EuiText } from "@elastic/eui"
import { navigate } from "gatsby"
import { CustomerDetailsForm } from "../../models"
import { Formik, FormikProps, FormikValues } from "formik"
import HailCXTextField from "../../components/form-controls/hailcx-text-field"
import HailCXSelectField from "../../components/form-controls/hailcx-select-field"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import * as Constants from "../../entities/constants"
import "../../styles/hailcx-forms.css"

const ConfirmDetailsPage = () => {
  const { customerStore } = useStore()
  const { customer, formValues, isLoading, updateCustomer } = customerStore

  const formRef = useRef<FormikProps<FormikValues>>(null)

  const data = {
    title: "Welcome",
    buttonContent: "Confirm details",
  }

  const handleClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleDetailsSubmit = async () => {
    const { mobile, confirmEmail, ...body } = formRef.current
      ?.values as CustomerDetailsForm

    const isUpdated = await updateCustomer({
      ...body,
      mobileNumber: mobile,
    })

    if (isUpdated) {
      if (customer && customer.repairTypeQuoted) {
        navigate("/book/ready-to-book")
      } else {
        navigate("/book/assessment")
      }
    } else {
      console.log("Error trying to update customer")
    }
  }

  return (
    <HailCXLayout
      header={<HailCXRightImageHeader title={data.title} progressValue={10} vehicleInfo={customerStore.getVehicleInfo()} />}
      footer={
        <HailCXSingleButtonFooter
          buttonContent={data.buttonContent}
          isLoading={isLoading}
          handleClick={handleClick}
        />
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1>Let's begin</h1>
        <p>We’ll just need some of your details.</p>
        <EuiSpacer />
      </EuiText>
      <Formik
        validationSchema={Constants.ValidationSchema}
        enableReinitialize
        initialValues={formValues}
        innerRef={formRef}
        onSubmit={handleDetailsSubmit}
      >
        {() => (
          <EuiForm
            component="form"
            style={{ minWidth: "75%", marginRight: "auto", marginLeft: "auto" }}
          >
            <HailCXTextField name="firstName" label="First Name" />
            <HailCXTextField name="lastName" label="Last Name" />
            <HailCXTextField name="mobile" label="Mobile" />
            <HailCXTextField name="email" label="Email" type="email" />
            <HailCXTextField
              name="confirmEmail"
              label="Confirm Email"
              type="email"
            />
          </EuiForm>
        )}
      </Formik>
    </HailCXLayout>
  )
}

export default observer(ConfirmDetailsPage)
